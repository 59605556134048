import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
    constructor(private router: Router) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const byString = (o, s): any => {
            s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
            s = s.replace(/^\./, ''); // strip a leading dot
            let a = s.split('.');
            for (let i = 0, n = a.length; i < n; ++i) {
                let k = a[i];
                if (k in o) {
                    o = o[k];
                } else {
                    return;
                }
            }
            return o;
        };
        let hasPermisson = true;
        for (const permission of route.data.permission) {
            if (!byString(environment.VALIDATION, permission)) {
                hasPermisson = false;
                break;
            }
        }

        if (hasPermisson) {
            return true;
        }
        this.router.navigate(['/pages/error/404']);
        // Navigate to 404
    }
}
