
import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { catchError, exhaustMap, Observable, take, tap, throwError } from "rxjs";
import { CandidateStatusLogService } from "../servives/candidate-status-log.service";
import { UserService } from "app/core/user/user.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor{
    
    constructor(public dialog: MatDialog,private _userService: UserService, private _candidateStatusLogService: CandidateStatusLogService){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>>{
              
              
                // const modifiedReq  = request.clone() 
                return next.handle(request).pipe(
                    catchError((error) => {
                        if(error instanceof HttpErrorResponse){
                           if(error.error instanceof ErrorEvent){
                               console.log('Error Event')
                           }else{
                               switch (error.status){
                                   case 401: //unauthorized
                                   console.log("Unauthorized");
                                   this._candidateStatusLogService.SessionExpired.next(true)
                                  
                                   break;
                                //    case 406: //unauthorized
                                //    console.log("ABCD");
                                //    this._candidateStatusLogService.SessionExpired.next(true)
                                //    break;
                               }
                           }
                        } else{
                            console.log('An error occured')
                        }
                        return throwError(() => (error))
                    })
                );        
        
    }    
}