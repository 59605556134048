// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://hrmsapi.vrinsofts.com/api",
  apibaseURL :'https://hrms.vrinsofts.com/',

  statusMaster:{
    0: 'Inactive',
    1: 'Active'
  },
  dropList:{
    1: 'SOURCED',
    2: 'SCREENING',
    3: 'INTERVIEW',
    4: 'PREBOARDING',
    5: 'HIRED',
    6: 'ARCHIVE STATE'
  },
  
  dropConnection:{
    SCREENING : ['INTERVIEW'],
    INTERVIEW : ['SCREENING'],
  },
  VALIDATION:{
    ACCESS_CONTROL:{},
    ROLES:{},
    USERS:{},
    
    SETTINGS: {},
    HIRING_FLOW:{},
    ARCHIVE_REASON:{},
    HIRING_ARCHIVE_REASON:{},
    PREBOARDING_ARCHIVE_REASON:{},
    JOB_ARCHIVE_REASON:{},
    EMAIL_TEMPLATE:{},
    CANDIDATE_SOURCE:{},
    DEPARTMENTS:{},

    JOB_LISTING:{},
    REOPEN_ARCHIVE_JOB:{},
    CANDIDATE_LIST:{},
    MOVE_STAGE:{},
    SCHEDULING_INTERVIEW:{},
    ADD_RATING:{},
    ARCHIVE_CANDIDATE:{},
    MOVE_TO_PREBOARDING:{},
    HIRING_TEAM:{},
    JOB_HIRING_FLOW:{},
    JOB_INFO:{},
    DASHBOARD:{},
    DASHBOARD_STATISTICS:{},
    CANDIDATE_FROM_SOURCES_CHART:{},
    RECRUITMENT_FUNNEL_CHART: {},
    CANDIDATE_PIPELINE_MOVEMENT_CHART:{},
    PREBOARDING:{},
    CANCEL_PREBOARDING: {}
    
  },
 
  statuses : {
    0: 'Initial Round',
    1: 'Technical Round',
    2: 'Practical  Round',
    3: 'MD  Round',
    4: 'HR  Round ',
    5: 'Negotiation/Finalization',
    6: 'Rejected',
  },
  navigation:{
      "default": [
        {
          "id": "apps.dashboard",
          "title": "Dashboard",
          "type": "basic",
          "icon": "heroicons_outline:dashboard",
          "link": "/apps/dashboard",
          "module": "DASHBOARD",
          "permission":"LIST"
        },
        {
          "id": "apps.candidate-management-module.inventory",
          "title": "Candidate Management",
          "type": "basic",
          "icon": "heroicons_outline:candidate-management",
          "link": "/apps/candidate-management"
        },
        {
          "id": "apps.candidate-tracking.inventory",
          "title": "Candidate Tracking",
          "type": "basic",
          "icon": "heroicons_outline:candidate-tracking",
          "link": "/apps/candidate-tracking"
        },
        {
          "id": "apps.calendar.inventory",
          "title": "Calendar",
          "type": "basic",
          "icon": "heroicons_outline:calendar",
          "link": "/apps/calendar"
        },
        // {
        //   "id": "apps.candidate-resource.inventory",
        //   "title": "Candidate Sources",
        //   "type": "basic",
        //   "icon": "heroicons_outline:candidate-sources",
        //   "link": "/apps/candidate-sources"
        // },
        // {
        //   "id": "apps.job-openings.job-opening",
        //   "title": "Job Openings",
        //   "type": "basic",
        //   "icon": "heroicons_outline:job-opening",
        //   "link": "/apps/job-openings"
        // },
        // {
        //   "id": "apps.dapartment-master.inventory",
        //   "title": "Departments",
        //   "type": "basic",
        //   "icon": "heroicons_outline:department",
        //   "link": "/apps/departments"
        // },
        {
          "id": "apps.employee-management.employee",
          "title": "Interviewer",
          "type": "basic",
          "icon": "heroicons_outline:employee-management",
          "link": "/apps/interviewer"
        },
        {
          "id": "apps.job",
          "title": "Jobs",
          "type": "basic",
          "icon": "heroicons_outline:job-opening",
          "link": "/apps/jobs",
          "module": "JOB_LISTING",
          "permission":"LIST"
        },
        {
          "id": "apps.preboarding",
          "title": "Preboarding",
          "type": "basic",
          "icon": "heroicons_outline:preboarding",
          "link": "/apps/preboarding",
          "module": "PREBOARDING",
          "permission":"LIST"
        },
        {
          "id": "apps.access-control",
          "title": "Access Control",
          "type": "collapsable",
          "icon": "heroicons_outline:employee-management",
          "module": "ACCESS_CONTROL",
          "permission":"LIST",
          "children": [
           
            {
              "id": "apps.access-control.roles",
              "title": "Roles & Permission",
              "type": "basic",
              "link": "/apps/access-control/roles-permission",
              "module": "ROLES",
              "permission":"LIST"
            },
            {
              "id": "apps.access-control.user",
              "title": "Users",
              "type": "basic",
              "link": "/apps/access-control/users",
              "module": "USERS",
              "permission":"LIST"
            },
          ]
        },
        {
          "id": "apps.settings",
          "title": "Settings",
          "type": "collapsable",
          "icon": "heroicons_outline:cog",
          "module": "SETTINGS",
          "permission":"LIST",
          "children": [
           
            {
              "id": "apps.settings.hiring-flow",
              "title": "Hiring Flows",
              "type": "basic",
              "link": "/apps/settings/hiring-flow",
              "module": "HIRING_FLOW",
              "permission":"LIST"
            },
            {
              "id": "apps.settings.archive-reasons",
              "title": "Archive Reasons",
              "type": "basic",
              "link": "/apps/settings/archive-reasons",
              "module": "ARCHIVE_REASON",
              "permission":"LIST"
            },
            {
              "id": "apps.settings.email-template",
              "title": "Email Template",
              "type": "basic",
              "link": "/apps/settings/email-template",
              "module": "EMAIL_TEMPLATE",
              "permission":"LIST"
            },
            {
              "id": "apps.settings.candidate-sources",
              "title": "Candidate Sources",
              "type": "basic",
              "link": "/apps/settings/candidate-sources",
              "module": "CANDIDATE_SOURCE",
              "permission":"LIST"
            },
            {
              "id": "apps.settings.departments",
              "title": "Departments",
              "type": "basic",
              "link": "/apps/settings/departments",
              "module": "DEPARTMENTS",
              "permission":"LIST"
            },
          ]
        },
        // {
        //   "id": "documentation",
        //   "title": "Documentation",
        //   "subtitle": "Everything you need to know about Fuse",
        //   "type": "group",
        //   "icon": "heroicons_outline:support",
        //   "children": [
        //     {
        //       "id": "documentation.changelog",
        //       "title": "Changelog",
        //       "type": "basic",
        //       "icon": "heroicons_outline:speakerphone",
        //       "link": "/docs/changelog",
        //       "badge": {
        //         "title": "14.2.0",
        //         "classes": "px-2 bg-yellow-300 text-black rounded-full"
        //       }
        //     },
        //     {
        //       "id": "documentation.guides",
        //       "title": "Guides",
        //       "type": "basic",
        //       "icon": "heroicons_outline:book-open",
        //       "link": "/docs/guides"
        //     }
        //   ]
        // },
   
        // {
          // "id": "pages",
          // "title": "Pages",
          // "subtitle": "Custom made page designs",
          // "type": "group",
          // "icon": "heroicons_outline:document",
          // "children": [
                // {
                //   "id": "apps.tasks",
                //   "title": "Tasks",
                //   "type": "basic",
                //   "icon": "heroicons_outline:check-circle",
                //   "link": "/apps/tasks"
                // },
      //         {
      //         "id": "pages.activities",
      //         "title": "Activities",
      //         "type": "basic",
      //         "icon": "heroicons_outline:menu-alt-2",
      //         "link": "/pages/activities"
      //       },
      //       {
      //         "id": "pages.authentication",
      //         "title": "Authentication",
      //         "type": "collapsable",
      //         "icon": "heroicons_outline:lock-closed",
      //         "children": [
      //           {
      //             "id": "pages.authentication.sign-in",
      //             "title": "Sign in",
      //             "type": "collapsable",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.sign-in.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-in.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-in/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.sign-up",
      //             "title": "Sign up",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/sign-up",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.sign-up.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-up.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-up/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.sign-out",
      //             "title": "Sign out",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/sign-out",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.sign-out.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.sign-out.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/sign-out/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.forgot-password",
      //             "title": "Forgot password",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/forgot-password",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.forgot-password.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.forgot-password.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/forgot-password/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.reset-password",
      //             "title": "Reset password",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/reset-password",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.reset-password.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.reset-password.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/reset-password/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.unlock-session",
      //             "title": "Unlock session",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/unlock-session",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.unlock-session.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.unlock-session.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/unlock-session/fullscreen-reversed"
      //               }
      //             ]
      //           },
      //           {
      //             "id": "pages.authentication.confirmation-required",
      //             "title": "Confirmation required",
      //             "type": "collapsable",
      //             "link": "/pages/authentication/confirmation-required",
      //             "children": [
      //               {
      //                 "id": "pages.authentication.confirmation-required.classic",
      //                 "title": "Classic",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/classic"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.modern",
      //                 "title": "Modern",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/modern"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.modern-reversed",
      //                 "title": "Modern Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/modern-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.split-screen",
      //                 "title": "Split Screen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/split-screen"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.split-screen-reversed",
      //                 "title": "Split Screen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/split-screen-reversed"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.fullscreen",
      //                 "title": "Fullscreen",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/fullscreen"
      //               },
      //               {
      //                 "id": "pages.authentication.confirmation-required.fullscreen-reversed",
      //                 "title": "Fullscreen Reversed",
      //                 "type": "basic",
      //                 "link": "/pages/authentication/confirmation-required/fullscreen-reversed"
      //               }
      //             ]
      //           }
      //         ]
      //       },
      //       {
      //         "id": "pages.profile",
      //         "title": "Profile",
      //         "type": "basic",
      //         "icon": "heroicons_outline:user-circle",
      //         "link": "/pages/profile"
      //       },
      //       {
      //         "id": "pages.settings",
      //         "title": "Settings",
      //         "type": "basic",
      //         "icon": "heroicons_outline:cog",
      //         "link": "/pages/settings"
      //       }
      //     ]
      //   },
      ]
    }
};