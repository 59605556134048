import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { environment } from 'environments/environment';
@Injectable({
    providedIn: 'root'
})
export class DashboardGuard implements CanActivate {
    /**
     * Constructor
     */
    constructor(private _authService: AuthService, private _router: Router) {}

    permission : any ;
   
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this.permission = environment.VALIDATION;
        if(this.permission.DASHBOARD.LIST){
           
          return true
        }
        this._router.navigate(['/apps/jobs/list']);
        return false
    }
}
