import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-msg-dialog',
  templateUrl: './success-msg-dialog.component.html',
  styleUrls: ['./success-msg-dialog.component.scss']
})
export class SuccessMsgDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<SuccessMsgDialogComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  message: string;
  ngOnInit(): void {

    this.message = this.data.message
    setTimeout(() => {
      this.message = null;
      this.close();
      // Mark for check
      this._changeDetectorRef.markForCheck();
  }, 5000)
  }
  // Close dialog
  close() {
    this.dialogRef.close();
  }
}
