
<div class="h-auto w-auto ">
    <div class="flex w-100 bg-card">  
        <div class="w-full text-3xl mt-1  font-medium tracking-tight">
            Session Expire
        </div>
    </div>
    <div  class="w-full my-2">
        <div class=" mt-5 text-xl font-medium">What happens next?</div>
        <ul>
            <li class="my-2 text-lg">You will be redirect to login page.</li>
            <li class="text-lg">Please Login Again!</li>
        </ul>

        <div class="flex justify-end mt-5">
            <button class="mat-raised-button mat-primary ml-2" (click)="RedirectToLoginPage()">
              <span class="mx-2"> Ok</span>
            </button>
        </div>
    </div>
</div>