import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

// import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  constructor(
    private _authService: AuthService, 
    private _router: Router,
    ) {}
  
  // loginUserId : any = parseInt(this._authService.user.id);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const id = route.paramMap.get('id');
    if(this._authService.user.id == id){
      return true
    }
    this._router.navigate(['/pages/error/404']);
    return false
  }
  
}
