import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/auth/auth.service';
import { CandidateStatusLogService } from '../../servives/candidate-status-log.service';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  
  constructor(
    private dialogRef: MatDialogRef<SessionTimeoutComponent>,
    private _router: Router,
    private _authService: AuthService,
    public dialog: MatDialog,
  ) { }
  ngOnInit(): void {
    
  }

  
  // Close dialog
  RedirectToLoginPage() {
    this._authService.signOut();
    this.dialog.closeAll();
    this._router.navigate(['/sign-in']);
    this.dialogRef.close();
  }
}