import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, Subject, switchMap, take } from 'rxjs';
import { InventoryPagination, InventoryProduct } from '../common.types';

@Injectable({
  providedIn: 'root'
})
export class CandidateStatusLogService {
    
  // Private
  private _pagination: BehaviorSubject<InventoryPagination | null> =
      new BehaviorSubject(null);
  private _product: BehaviorSubject<InventoryProduct | null> =
      new BehaviorSubject(null);
  private _products: BehaviorSubject<InventoryProduct[] | null> =
      new BehaviorSubject(null);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) { }


  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------



  SessionExpired = new Subject();
    /**
     * Getter for pagination
     */
     get pagination$(): Observable<InventoryPagination> {
      return this._pagination.asObservable();
  }

  /**
   * Getter for product
   */
  get product$(): Observable<InventoryProduct> {
      return this._product.asObservable();
  }

  /**
   * Getter for products
   */
  productList: any = [];
  get products$(): Observable<InventoryProduct[]> {
      return this._products.asObservable().pipe(
          map((data) => {
              this.productList = data;
              return data;
          })
      );
  }

     // Add Notes
     addNotes(
      candidate_id: number,
      is_candidate_status: number,
      previous_status: number,
      description?: string,
      reason?: number,
  ): Observable<InventoryProduct> {
      return this.products$.pipe(
          take(1),
          switchMap((products) => {
              const updatedformData = new FormData();

              if (candidate_id) {
                  updatedformData.append(
                      'candidate_id',
                      candidate_id.toString()
                  );
              }
              if (is_candidate_status) {
                  updatedformData.append(
                      'is_candidate_status',
                      is_candidate_status.toString()
                  );
              }

              if (description) {
                  updatedformData.append('description', description);
              }

              if (reason !== null && reason !== undefined) {
                  updatedformData.append('reason', reason.toString());
              }

              return this._httpClient.post<InventoryProduct>(
                  environment.apiUrl + '/candidate_status_log',
                  { 
                      candidate_id: candidate_id,
                      is_candidate_status: is_candidate_status,
                      description: description,
                      reason: reason,
                      previous_status: previous_status,
                   },
                  {}
              );
          })
      );
  }
}
