import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { environment } from 'environments/environment';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CKEditorModule } from 'ckeditor4-angular';
import { SuccessMsgDialogComponent } from './modules/admin/common/component/success-msg-dialog/success-msg-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { CookieService } from 'ngx-cookie-service';
import { FailureMesgDialogComponent } from './modules/admin/common/component/failure-mesg-dialog/failure-mesg-dialog.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { AuthInterceptorService } from './modules/admin/common/interceptor/session-out.interceptor';
import { SessionTimeoutComponent } from './modules/admin/common/component/session-timeout/session-timeout.component';
import { NgApexchartsModule } from 'ng-apexcharts';
// import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';


const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
        SuccessMsgDialogComponent,
        FailureMesgDialogComponent,
        SessionTimeoutComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        NgApexchartsModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        CKEditorModule,
        NgxSliderModule,
        // Layout module of your application
        LayoutModule,
        MatIconModule,
        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({})
    ],
    bootstrap   : [
        AppComponent
    ],
    providers:[
        { 
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { 
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {provide: "BASE_API_URL",useValue: environment.apiUrl },
        CookieService
    ]
})
export class AppModule
{
}
