import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ProfileGuard } from './core/auth/guards/profile.guard';
import { DashboardGuard } from './core/auth/guards/dashboard.guard';
import { PermissionGuard } from './core/auth/guards/permission-guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'apps/dashboard' },
    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'apps/dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then((m) => m.AuthConfirmationRequiredModule)
            },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then((m) => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then((m) => m.AuthSignUpModule) },
            { path: 'job/list', loadChildren: () => import('app/modules/admin/apps/website-page/website-page.module').then((m) => m.WebsitePageModule) },
            { path: 'job/list/details/:id', loadChildren: () => import('app/modules/admin/apps/webjob-details/webjob-details.module').then((m) => m.WebjobDetailsModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then((m) => m.AuthUnlockSessionModule) }
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [{ path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then((m) => m.LandingHomeModule) }]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver
        },
        children: [
            // Apps
            {
                path: 'apps',
                children: [
                    {
                        path: 'candidate-management',
                        loadChildren: () => import('app/modules/admin/apps/candidate-management/candidate-management.module').then((m) => m.CandidateManagementModule)
                    },
                    {
                        path: 'candidate-tracking',
                        loadChildren: () => import('app/modules/admin/apps/candidate-tracker/candidate-tracker.module').then((m) => m.CandidateTrackingModule),
                       
                    },
                    // { path: 'candidate-sources', loadChildren: () => import('app/modules/admin/apps/candidate-sources/candidate-sources.module').then((m) => m.CandidateSources) },
                    { path: 'dashboard', loadChildren: () => import('app/modules/admin/apps/dashboard/dashboard.module').then((m) => m.DashboardModule), canActivate:[DashboardGuard] },
                    
                    // { path: 'departments', loadChildren: () => import('app/modules/admin/apps/department-master/departments.module').then((m) => m.DepartmentasterModule) },

                    { path: 'calendar', loadChildren: () => import('app/modules/admin/apps/calendar/calendar.module').then((m) => m.CalendarModule) },

                    {
                        path: 'interviewer',
                        loadChildren: () => import('app/modules/admin/apps/employee-management/employee-management.module').then((m) => m.EmployeeManagementModule)
                    },
                    {
                        path: 'job-openings',
                        loadChildren: () => import('app/modules/admin/apps/job-openings/job-opening.module').then((m) => m.JobOpeningModule)
                    },
                    {
                        path: 'job/view/:id', data: { permission: ['JOB_LISTING.LIST'] },
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('app/modules/admin/apps/job-details/job-details.module').then((m) => m.JobDetailsModule)
                    },
                    {
                        path: 'applicants-details/:id',
                        loadChildren: () => import('app/modules/admin/apps/applicant-details/applicant-details.module').then((m) => m.ApplicantDetailsModule)
                    },
                    {
                        path: 'preboarding', data: { permission: ['PREBOARDING.LIST'] },
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('app/modules/admin/apps/preboarding/preboarding.module').then((m) => m.PreboardingModule)
                    },
                    {
                        path: 'jobs', data: { permission: ['JOB_LISTING.LIST'] },
                        canActivate: [PermissionGuard],
                        loadChildren: () => import('app/modules/admin/apps/jobs/job.module').then((m) => m.JobsModule)
                    },
                    {
                        path: 'candidate/:id',
                        loadChildren: () => import('app/modules/admin/apps/candidate-details/candidate-details.module').then((m) => m.CandidateDetailsModule)
                    },
                  
                    { path: 'settings',  data: { permission: ['SETTINGS.LIST'] },
                    canActivate: [PermissionGuard], loadChildren: () => import('app/modules/admin/apps/settings/settings.module').then((m) => m.SettingsModule) },
                   
                    { path: 'profile/:id', canActivate: [ProfileGuard], loadChildren: () => import('app/modules/admin/apps/user-details/user-details.module').then((m) => m.UserDetailsModule) },
                   
                    { path: 'access-control', data: { permission: ['ACCESS_CONTROL.LIST'] },
                    canActivate: [PermissionGuard], loadChildren: () => import('app/modules/admin/apps/access-control/access-control.module').then((m) =>m.AccessControlModule)},
                   
                    { path: 'job/list', data: { permission: ['JOB_LISTING.LIST'] },
                    canActivate: [PermissionGuard], loadChildren: () => import('app/modules/admin/apps/website-page/website-page.module').then((m) => m.WebsitePageModule) },
                   
                    { path: 'job/list/details/:id', data: { permission: ['JOB_LISTING.LIST'] },
                    canActivate: [PermissionGuard], loadChildren: () => import('app/modules/admin/apps/webjob-details/webjob-details.module').then((m) => m.WebjobDetailsModule) },
                    // { path: 'tasks', loadChildren: () => import('app/modules/admin/apps/tasks/tasks.module').then((m) => m.TasksModule) }
                ]
            },

            // Pages
            {
                path: 'pages',
                children: [
                    // Activities
                    { path: 'activities', loadChildren: () => import('app/modules/admin/pages/activities/activities.module').then((m) => m.ActivitiesModule) },

                    // Authentication
                    { path: 'authentication', loadChildren: () => import('app/modules/admin/pages/authentication/authentication.module').then((m) => m.AuthenticationModule) },

                    // Coming Soon
                    { path: 'coming-soon', loadChildren: () => import('app/modules/admin/pages/coming-soon/coming-soon.module').then((m) => m.ComingSoonModule) },

                    // Error
                    {
                        path: 'error',
                        children: [
                            { path: '404', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then((m) => m.Error404Module) },
                            { path: '500', loadChildren: () => import('app/modules/admin/pages/error/error-500/error-500.module').then((m) => m.Error500Module) }
                        ]
                    },

                    // Invoice
                    {
                        path: 'invoice',
                        children: [
                            {
                                path: 'printable',
                                children: [
                                    {
                                        path: 'compact',
                                        loadChildren: () => import('app/modules/admin/pages/invoice/printable/compact/compact.module').then((m) => m.CompactModule)
                                    },
                                    { path: 'modern', loadChildren: () => import('app/modules/admin/pages/invoice/printable/modern/modern.module').then((m) => m.ModernModule) }
                                ]
                            }
                        ]
                    },

                    // Maintenance
                    { path: 'maintenance', loadChildren: () => import('app/modules/admin/pages/maintenance/maintenance.module').then((m) => m.MaintenanceModule) },

                    // Pricing
                    {
                        path: 'pricing',
                        children: [
                            { path: 'modern', loadChildren: () => import('app/modules/admin/pages/pricing/modern/modern.module').then((m) => m.PricingModernModule) },
                            { path: 'simple', loadChildren: () => import('app/modules/admin/pages/pricing/simple/simple.module').then((m) => m.PricingSimpleModule) },
                            { path: 'single', loadChildren: () => import('app/modules/admin/pages/pricing/single/single.module').then((m) => m.PricingSingleModule) },
                            { path: 'table', loadChildren: () => import('app/modules/admin/pages/pricing/table/table.module').then((m) => m.PricingTableModule) }
                        ]
                    },

                    // Profile
                    { path: 'profile', loadChildren: () => import('app/modules/admin/pages/profile/profile.module').then((m) => m.ProfileModule) },

                    // Settings
                    { path: 'settings', loadChildren: () => import('app/modules/admin/pages/settings/settings.module').then((m) => m.SettingsModule) }
                ]
            },

            // User Interface
            {
                path: 'ui',
                children: [
                    // Material Components
                    {
                        path: 'material-components',
                        loadChildren: () => import('app/modules/admin/ui/material-components/material-components.module').then((m) => m.MaterialComponentsModule)
                    },

                    // Fuse Components
                    { path: 'fuse-components', loadChildren: () => import('app/modules/admin/ui/fuse-components/fuse-components.module').then((m) => m.FuseComponentsModule) },

                    // Other Components
                    { path: 'other-components', loadChildren: () => import('app/modules/admin/ui/other-components/other-components.module').then((m) => m.OtherComponentsModule) },

                    // TailwindCSS
                    { path: 'tailwindcss', loadChildren: () => import('app/modules/admin/ui/tailwindcss/tailwindcss.module').then((m) => m.TailwindCSSModule) },

                    // Advanced Search
                    { path: 'advanced-search', loadChildren: () => import('app/modules/admin/ui/advanced-search/advanced-search.module').then((m) => m.AdvancedSearchModule) },

                    // Animations
                    { path: 'animations', loadChildren: () => import('app/modules/admin/ui/animations/animations.module').then((m) => m.AnimationsModule) },

                    // Cards
                    { path: 'cards', loadChildren: () => import('app/modules/admin/ui/cards/cards.module').then((m) => m.CardsModule) },

                    // Colors
                    { path: 'colors', loadChildren: () => import('app/modules/admin/ui/colors/colors.module').then((m) => m.ColorsModule) },

                    // Confirmation Dialog
                    {
                        path: 'confirmation-dialog',
                        loadChildren: () => import('app/modules/admin/ui/confirmation-dialog/confirmation-dialog.module').then((m) => m.ConfirmationDialogModule)
                    },

                    // Datatable
                    { path: 'datatable', loadChildren: () => import('app/modules/admin/ui/datatable/datatable.module').then((m) => m.DatatableModule) },

                    // Forms
                    {
                        path: 'forms',
                        children: [
                            { path: 'fields', loadChildren: () => import('app/modules/admin/ui/forms/fields/fields.module').then((m) => m.FormsFieldsModule) },
                            { path: 'layouts', loadChildren: () => import('app/modules/admin/ui/forms/layouts/layouts.module').then((m) => m.FormsLayoutsModule) },
                            { path: 'wizards', loadChildren: () => import('app/modules/admin/ui/forms/wizards/wizards.module').then((m) => m.FormsWizardsModule) }
                        ]
                    },

                    // Icons
                    { path: 'icons', loadChildren: () => import('app/modules/admin/ui/icons/icons.module').then((m) => m.IconsModule) },

                    // Page Layouts
                    { path: 'page-layouts', loadChildren: () => import('app/modules/admin/ui/page-layouts/page-layouts.module').then((m) => m.PageLayoutsModule) },

                    // Typography
                    { path: 'typography', loadChildren: () => import('app/modules/admin/ui/typography/typography.module').then((m) => m.TypographyModule) }
                ]
            },

            // Documentation
            // {
            //     path: 'docs',
            //     children: [
            //         // Changelog
            //         { path: 'changelog', loadChildren: () => import('app/modules/admin/docs/changelog/changelog.module').then((m) => m.ChangelogModule) },

            //         // Guides
            //         { path: 'guides', loadChildren: () => import('app/modules/admin/docs/guides/guides.module').then((m) => m.GuidesModule) }
            //     ]
            // },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/error/error-404/error-404.module').then((m) => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
