<div class="justify-content-center w-100 h-70">
    <div class="grid justify-items-stretch">
        <div class="justify-self-end">
            <mat-icon class="cursor-pointer self-end" (click)="close()" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </div> 
    </div>
    <div class="pb-5 flex justify-center">  
        <mat-icon class="text-green-500" [svgIcon]="'heroicons_outline:check'"></mat-icon>
        <div>{{message}}</div>
    </div>
</div>
