import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './core/user/user.service';
import { SessionTimeoutComponent } from './modules/admin/common/component/session-timeout/session-timeout.component';
import { CandidateStatusLogService } from './modules/admin/common/servives/candidate-status-log.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit
{

    TimeOutHandelar:any;
    /**
     * Constructor
     */
    constructor(  private _router: Router,
        private _userService: UserService,
        private _authService: AuthService,
        private _candidateStatusLogService: CandidateStatusLogService,
        public dialog : MatDialog)
    {
        if(localStorage.getItem('VALIDATION')){
            environment.VALIDATION= JSON.parse(localStorage.getItem('VALIDATION'))
        }
        this._authService.isLoggedIn = localStorage.getItem('accessToken')? true : false ;
        // this.ValidateSession();
    }

    ngOnInit(): void {
        this._authService.UserLoginSccessfully.subscribe((res)=>{
            if(res){
                this.ValidateSession();
            }
        })
        this._candidateStatusLogService.SessionExpired.subscribe((res)=>{
            if(res &&  this._authService.accessToken){
              const ref = this.dialog.open(SessionTimeoutComponent, { disableClose: true });
            }
          })
    }


    ValidateSession(){
        this.TimeOutHandelar = setTimeout(() => {
            
            if(localStorage.getItem('user') && this._authService.isLoggedIn){
              const id= JSON.parse(localStorage.getItem('user')).id
               this._userService.geUserById(id,false).subscribe((res: any)=>{
                   if(res.payload.status == 0){
                    this._authService.signOut();
                    this._router.navigate(['/sign-in']);
                    // clearInterval(interval)
                   }else{
                       this.ValidateSession();
                   }
               })
            }else{
                this.ValidateSession();
            }
            
        }, 60000);
    }
}
