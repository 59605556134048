<div class="justify-content-center w-100 h-70">
    <div class="grid justify-items-stretch">
        <div class="justify-self-end">
            <mat-icon class="cursor-pointer self-end" (click)="close()" [svgIcon]="'heroicons_outline:x'"></mat-icon>
        </div> 
    </div>
    <div class="pb-6 flex justify-center"> 
        <mat-icon class="text-warn-500 icon-size-6 mr-1" svgIcon="warning_amber"></mat-icon>
        <div class="text-danger text-lg font-normal">{{message}}</div>
    </div>
</div>
