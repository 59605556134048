import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, Subject, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { User } from '../user/user.types';
import { environment } from 'environments/environment';

@Injectable({
    providedIn:'root'
})
export class AuthService
{
    public _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    isUserLogin: boolean = false;
    /**
     * Setter & getter for user
     *
     * @param value
     */
     set isLoggedIn(value: boolean)
     {
         // Set the value
         this.isUserLogin = value
     }
 
     get isLoggedIn(): boolean
     {
         return this.isUserLogin;
     }

 
    UserLoginSccessfully = new Subject()
 
    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('accessToken') ?? '';
    }


    set user(data: any)
    {
        
        localStorage.setItem('user', JSON.stringify(data));
    }

    get user(): User
    {
        return JSON.parse(localStorage.getItem('user')) ?? {};
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any>
    {
        return this._httpClient.post(environment.apiUrl +'/forgot-password',{
            email
        });
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any>
    {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated )
        {
            return throwError('User is already logged in.');
        }
        return this._httpClient.post( environment.apiUrl+ '/login',{name:credentials.email,password: credentials.password}).pipe(
            switchMap((response: any) => {

                // Store the access token in the local 
                this.accessToken = response.payload.token;
                this.user = response.payload.user;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.payload.user;
                environment.VALIDATION = response.payload.permission;
                localStorage.setItem('VALIDATION',JSON.stringify(response.payload.permission) )
                
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        // Renew token
        
        return this._httpClient.post('api/auth/refresh-access-token', {
            accessToken: this.accessToken
        }).pipe(
            catchError(() =>

                // Return false
                of(false)
            ),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Store the user on the user service
                this._userService.user = response.user;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any>
    {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }
        this._userService.user = this.user;
        // If the access token exists and it didn't expire, sign in using it
        return  of(true) // this.signInUsingToken();
    }
}
